<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="flex h-screen flex-col items-center gap-4 p-4 place-content-center bg-base-2"
  >
    <div
      v-if="!registeredError || !registeredError.registeredException"
      class="flex max-w-screen-lg flex-col rounded border border-neutral-7 contrast:border-base-1 p-4 shadow-2xl"
    >
      <div class="w-full md:w-128 p-4 mx-auto">
        <template v-if="isNotFound">
          <h1 class="text-4xl md:text-6xl font-bold text-primary-1 text-center">
            404
          </h1>
          <p class="mt-4 text-lg md:text-xl font-semibold text-center">
            {{ localization.pageNotFound }}
          </p>
          <p class="mt-2 text-center text-base md:text-lg">
            {{ localization.pageNotFoundDescription }}
          </p>
        </template>
        <template v-else-if="isAccessDenied">
          <h1 class="text-4xl md:text-6xl font-bold text-primary-1 text-center">
            403
          </h1>
          <p class="mt-4 text-lg md:text-xl font-semibold text-center">
            {{ localization.accessDenied }}
          </p>
          <p class="mt-2 text-center text-base md:text-lg">
            {{ localization.accessDeniedDescription }}
          </p>
        </template>
        <template v-else>
          <h1 class="text-lg md:text-xl text-center">
            {{ localization.errorOccuredNotRegistered }} {{ error.statusCode }}
          </h1>
        </template>

        <template v-if="!isNotFound && !isAccessDenied">
          <HorizontalSeparator class="my-4" />
          <p
            v-if="
              error.data && typeof error.data !== 'string' && error.data.title
            "
            class="text-base md:text-lg text-center"
          >
            {{ error.data.title }}
          </p>
          <p v-else class="text-base md:text-lg text-center">
            {{ error.message }}
          </p>
        </template>

        <ControlButton
          id="back_to_main_page"
          :inverted="true"
          class="w-full mt-4 p-2"
          button-style="button-narrow"
          @click="handleError"
        >
          {{ localization.back }}
        </ControlButton>
      </div>
    </div>

    <div
      v-else
      class="flex max-w-screen-lg flex-col rounded border border-neutral-7 contrast:border-base-1 p-4 shadow-2xl text-base-1"
    >
      <h1>
        {{ localization.errorOccured }}
        {{ registeredError.exceptionCode }}
      </h1>
      <p>
        {{ localization.saveErrorCode }}
        <i>{{ localization.savedErrorCodeRecipient }} </i>
        <ControlLink
          :label="localization.supportEmail"
          :href="'mailto:' + localization.supportEmail"
        ></ControlLink
        >. {{ localization.sendErrorDetails }}
      </p>
      <HorizontalSeparator class="my-4" />
      <p>{{ localization.errorReturnInstruction }}</p>

      <ControlButton
        id="back_to_main_page"
        :inverted="true"
        class="w-full mt-4 p-2"
        button-style="button-narrow"
        @click="handleError"
      >
        {{ localization.back }}
      </ControlButton>
    </div>
    <div
      v-if="
        isDev &&
        !isNotFound &&
        !isAccessDenied &&
        registeredError &&
        registeredError.exceptionStackTrace
      "
      class="w-full overflow-auto text-xs text-base-1"
    >
      <HorizontalSeparator v-if="error.stack" class="my-4" />
      <pre class="w-full">{{ error.message }}</pre>
      <pre class="w-full" v-html="registeredError.exceptionStackTrace"></pre>
    </div>
    <div
      v-else-if="isDev && !isNotFound && !isAccessDenied"
      class="w-full overflow-auto p-4 text-xs text-base-1"
    >
      <HorizontalSeparator v-if="error.data" class="my-4" />
      <pre v-if="error.data" class="w-full">{{ error.data }}</pre>
      <HorizontalSeparator v-if="error.stack" class="my-4" />
      <pre class="w-full">{{ error.message }}</pre>
      <pre class="w-full" v-html="error.stack"></pre>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { RegisteredError } from "common-components-nuxt-types/common/error";
import {
  isRegisteredError,
  exceptionFromNuxtError,
} from "./server/utils/error";
import type { NuxtError } from "nuxt/app";

useTheme();

const localization = useGlobalLocalization();

interface Props {
  error: NuxtError<string | { title: string }>;
}
const props = defineProps<Props>();
const registeredError = ref<RegisteredError>();
const isDev = process.env.NODE_ENV !== "production";

const errorData = ref<unknown>();

try {
  if (props.error.data !== undefined && typeof props.error.data === "string") {
    errorData.value = JSON.parse(props.error.data);
  } else {
    errorData.value = props.error.data;
  }
} catch {
  errorData.value = props.error.data;
}
const isNotFound = computed(
  () => props.error && props.error.statusCode === 404,
);

const isAccessDenied = computed(
  () => props.error && props.error.statusCode === 403,
);

if (!isRegisteredError(errorData.value)) {
  if (isNotFound.value) {
    await registerNotFound(props.error);
  } else if (!isAccessDenied.value) {
    await registerError(props.error);
  }
} else {
  registeredError.value = errorData.value;
}

async function registerNotFound(error: NuxtError) {
  try {
    await $fetch("/api/notFoundPage", {
      headers: {
        "x-csrf-protection": "1",
      },
      retry: 0,
      method: "post",
      body: exceptionFromNuxtError(error),
    });
  } catch (e) {
    console.error(e);
  }
}
async function registerError(error: NuxtError) {
  try {
    const errorCodeResponse = await $fetch("/api/error", {
      headers: {
        "x-csrf-protection": "1",
      },
      retry: 0,
      method: "post",
      body: exceptionFromNuxtError(error),
    });
    registeredError.value = {
      exceptionCode: errorCodeResponse,
      registeredException: true,
    };
  } catch {
    registeredError.value = {
      exceptionCode: "#ERR",
      registeredException: false,
    };
  }
}

const handleError = async () => {
  clearNuxtData();
  clearNuxtState();
  await clearError({ redirect: "/" });
  reloadNuxtApp({ force: true, persistState: false });
};
</script>
