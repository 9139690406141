
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as accessTokenTQynULTExDMeta } from "/var/lib/jenkins/workspace/PZO_master@2/client/common-components-nuxt/pages/accessToken.vue?macro=true";
import { default as declaration1XULkqhzj6Meta } from "/var/lib/jenkins/workspace/PZO_master@2/client/switcher-public/pages/declaration.vue?macro=true";
import { default as devDomainwuxMzJWgVuMeta } from "/var/lib/jenkins/workspace/PZO_master@2/client/common-components-nuxt/pages/devDomain.vue?macro=true";
import { default as indexh5t2OJ9gSfMeta } from "/var/lib/jenkins/workspace/PZO_master@2/client/switcher-public/pages/index.vue?macro=true";
import { default as notSupportedBrowser5ffdnbI5NLMeta } from "/var/lib/jenkins/workspace/PZO_master@2/client/common-components-nuxt/pages/notSupportedBrowser.vue?macro=true";
export default [
  {
    name: "accessToken",
    path: "/accessToken",
    meta: accessTokenTQynULTExDMeta || {},
    component: () => import("/var/lib/jenkins/workspace/PZO_master@2/client/common-components-nuxt/pages/accessToken.vue")
  },
  {
    name: "declaration",
    path: "/declaration",
    component: () => import("/var/lib/jenkins/workspace/PZO_master@2/client/switcher-public/pages/declaration.vue")
  },
  {
    name: "devDomain",
    path: "/devDomain",
    meta: devDomainwuxMzJWgVuMeta || {},
    component: () => import("/var/lib/jenkins/workspace/PZO_master@2/client/common-components-nuxt/pages/devDomain.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/lib/jenkins/workspace/PZO_master@2/client/switcher-public/pages/index.vue")
  },
  {
    name: "notSupportedBrowser",
    path: "/notSupportedBrowser",
    meta: notSupportedBrowser5ffdnbI5NLMeta || {},
    component: () => import("/var/lib/jenkins/workspace/PZO_master@2/client/common-components-nuxt/pages/notSupportedBrowser.vue")
  }
]